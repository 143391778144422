body.news .news-items {
  //padding: $spacing-unit;
}

body.news .wrapper {
  max-width: 670px;
}

article.post {
  padding: $spacing-unit;
  img, iframe {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    display: block;
    align-self: center;
  }
}
