$current-nav-link-color: transparentize(#7e00db, 0.6);
$site-nav-line-height: 50px;
$site-nav-width: 98%;


@mixin current-nav-link {
  color: #fff;
  text-decoration: underline;
}

.site-nav {


  line-height: $site-nav-line-height;
  background-color: $black-color;
  border-radius: $brand-corner-radius;
  box-sizing: border-box;
  width: $site-nav-width;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  padding-left: $unit;
  padding-right: $unit;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-around;
  a {
    color: $brand-primary;
    
	  
  }
  ul {
    list-style: none; 
    margin: 0; 
    padding: 0; 
    display: flex; 
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    ul {
      flex-direction: column;
      display: none;
    }
    li {
      display: block;
      padding: 0 20px;
      &:hover {
        ul {
          display: block;
          position: absolute;
          z-index: 100;
          background-color: $black-color;
          border-radius: $brand-corner-radius;
        }
      }
    }
    
  }
  
    @include media-query($on-palm) {
      line-height: normal;
      font-size: 21.6px;
      ul {
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
        ul {
          display: block;
          position: relative;
          padding-left: 15px;
        }
        li {
          padding: 10px;
          &:hover {
            ul {
              position: relative;
              background-color: transparent;
              border: none;
            }
          }
        }
      }
    }
  }
  
  body.attend .page-link.attend,
  body.awards .page-link.awards,
  body.schedule .page-link.schedule,
  body.gamemaking-frenzy .page-link.gamemaking-frenzy,
  body.games .page-link.games,
  body.news .page-link.news,
  body.safespace .page-link.safespace,
  body.starcade .page-link.starcade,
  body.submit .page-link.submit {
    @include current-nav-link;
  }