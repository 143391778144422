$site-title-size: 100px;
$site-title-padding: $unit/2;

$site-title-side-info-width: 20%;
$site-title-side-top-padding: 35px;
$site-title-left-side-font-size: 28px;


/**
 * Site header
 */

 .site-header {
   h1, h2, h3, h4 {
     font-family: $title-font-family;
   }
 }

.home-header-wrapper {
  display: flex;

  .title-block {
    flex: 1;
    text-align: center;
    @include media-query($on-palm) {
      h1 {
        margin-bottom: 0;
      }
    }

  }
}

a.site-title {
  font-size: $site-title-size;
  @include black-text-shadow;
  margin-bottom: auto;
  line-height: normal;
  padding: $site-title-padding;
  color: $brand-primary;
  text-transform: uppercase;
  &:hover {
    text-decoration: none;
    color: $brand-primary;
  }
  @include media-query($on-palm) {
    font-size: 46px;
    line-height: normal;
  }
}
.date, .location {
  text-align: center;
  width: $site-title-side-info-width;
  padding-top: $site-title-side-top-padding;
  box-sizing: border-box;
  @include media-query($on-palm) {
    display: none;
  }
}
.date {
  h3 {
    margin-bottom: auto;
  }
}
.location {
  font-size: $site-title-left-side-font-size;
  h2 {
    margin-bottom: auto;
  }
}

