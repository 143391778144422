$calendar-header-color: #b9d3cb;

//events

.announce {
  text-align: center;
  color: $purple;
  text-shadow: 0px 3px 2px rgba(150, 150, 150, 0.58);
}

body.schedule .page-content{
  .logo-splash {
    height: 360px;
    margin-left: auto;
    margin-right: auto;
  }
  .headline-em {
    text-align: center;
  }
}
.wrapper {
  max-width: none;
  padding-left: 0;
  padding-right: 0;
}
.calendar {
  list-style: none;
  margin-left: 0;
  h3 {
    background-color: $calendar-header-color;
    padding: $unit;
    text-align: center;
    margin-bottom: 0;
  }
  .event {
    padding-left: $spacing-unit;
    padding-right: $spacing-unit;
    min-height: 200px;
    display: flex;
    flex-flow: row wrap;
    background-color: lighten($cream-color, 10%);
    margin-bottom: $unit;
    box-shadow: 0px 3px 0px 0px rgba(0,0,0,0.1);
    &.featured {
      background-color: #f8c672;
    }
    .content {
      flex: 1;
      order:1;
      min-width: 300px;
      margin: $unit;
      @include media-query($on-palm) {
        order: 2;
      }
    }
    .thumbnail {
      min-height: 200px;
      width: 400px;
      min-width: 200px;
      order: 2;
      overflow: hidden;
      margin: $unit;
      @include media-query($on-palm) {
        order: 1;
      }
      img {
        border-radius: $brand-corner-radius;
      }
    }
    &:nth-child(odd) {
      .thumbnail {
        order: 1;
      }
      .content {
        order: 2;
      }
    }
  }
}
