



// columns
.col {
  float: left;
  margin-bottom: $spacing-unit / 2;
  padding-left: $spacing-unit / 2;
}

.col-1 {
  width: -webkit-calc(35% - (#{$spacing-unit} / 2));
  width:         calc(35% - (#{$spacing-unit} / 2));
}

.col-2 {
  width: -webkit-calc(20% - (#{$spacing-unit} / 2));
  width:         calc(20% - (#{$spacing-unit} / 2));
}

.col-3 {
  width: -webkit-calc(45% - (#{$spacing-unit} / 2));
  width:         calc(45% - (#{$spacing-unit} / 2));
}

.col-4 {
  width: -webkit-calc(65% - (#{$spacing-unit} / 2));
  width:         calc(65% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
  .col-1,
  .col-2 {
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width:         calc(50% - (#{$spacing-unit} / 2));
  }

  .col-3 {
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}

@include media-query($on-palm) {
  .col {
    float: none;
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}



/**
 * Page content
 */
 body {
   display: flex;
   min-height: 100vh;
   flex-direction: column;
   main {
     flex: 1;
   }
   &.index main{
     display: flex;
     .container {
       align-self: center;
     }
   }
 }


.page-content .container {
  margin: $unit auto;
  width: 100%;
  background-color: $cream-color;
  color: #111;
  max-width: 1000px;
  border-radius: $brand-corner-radius;
  box-sizing: border-box;
  p {
    padding: $unit;
  }
  header {
    background-color: darken($cream-color, 10%);
    border-top-right-radius: $brand-corner-radius;
    border-top-left-radius: $brand-corner-radius;
    padding: $unit /2;
    margin-bottom: $unit;
    h1 {
      line-height: normal;
      color: $brand-secondary;
      margin-bottom: 0;
      margin-left: $unit /2;
    }
  }
}

.awards .page-content .container, .games .page-content .container, .games-archive .page-content .container {
  background-color: inherit;
  color: $cream-color;
  header {
    display: none;
  }
  a {
    color: $brand-primary;
    &:hover {
      color: saturate($brand-primary, 20%);
    }
  }

}

.page-heading {
  font-size: 20px;
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
}

.post-link {
  display: block;
  font-size: 24px;
}

.game img {
  border-radius: 3px;
}

/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.post-title {
  font-size: 42px;
  letter-spacing: -1px;
  line-height: 1;

  @include media-query($on-laptop) {
    font-size: 36px;
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  h2 {
    font-size: 32px;
    @include media-query($on-laptop) {
      font-size: 28px;
    }
  }

  h3 {
    font-size: 26px;

    @include media-query($on-laptop) {
      font-size: 22px;
    }
  }

  h4 {
    font-size: 20px;

    @include media-query($on-laptop) {
      font-size: 18px;
    }
  }
}

.flex {
  display: flex;
}

.row {
  flex-direction: row;
}

.center {
  text-align: center;
}

.right {
  float: right;
}

.left {
  float: left;
}
