@include keyframes(fadeOutRight) {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    @include transform(translateX($saf-translateX-distance) translateZ(0));
  }
}

@mixin fadeOutRight($duration: 1s, $delay: 0s, $fill-mode: forwards) {
  @include animation(fadeOutRight $duration $delay $fill-mode);
}
