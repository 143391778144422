.game-page {
  img {
    margin-left: auto;
    margin-right: auto;
  }
  .wrapper {
    padding-left: $spacing-unit;
    padding-right: $spacing-unit;
    padding-bottom: 18px;
  }
  .video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 35px;
    height: 0;
    overflow: hidden;
    iframe {
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
    }
  }
}
