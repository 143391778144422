body.index {
  .page-content {
    padding: $unit;
  }
  .container {
    max-width: 1800px;
  }
  .bx-wrapper, .bx-viewport {
    max-height: 60vh;
  }
}
