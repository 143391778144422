@include keyframes(dropIn) {
  0% {
    opacity: 0;
    @include transform(translateY($saf-drop-rise-distance * -1) rotate(0) translateZ(0));
  }
  10% {
    @include transform(translateY($saf-drop-rise-distance * -1) rotate(-40deg));
  }
  90% {
    opacity: 1;
  }
  100% {
    @include transform(translateY(0) rotate(0));
  }
}

@mixin dropIn($duration: 0.5s, $delay: 0s, $fill-mode: forwards) {
  @include animation(dropIn $duration $delay $fill-mode);
}
