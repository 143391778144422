@include keyframes(shake) {
  0%, 100% {
    @include transform(translateX(0) translateZ(0));
  }
  15% {
    @include transform(translateX($saf-shake-distance));
  }
  35% {
    @include transform(translateX($saf-shake-distance * -1));
  }
  50% {
    @include transform(translateX($saf-shake-distance / 2));
  }
  65% {
    @include transform(translateX($saf-shake-distance / 2 * -1));
  }
  85% {
    @include transform(translateX($saf-shake-distance / 3));
  }
}

@mixin shake($duration: 0.5s, $delay: 0s, $fill-mode: none, $count: 1) {
  @include animation(shake $duration $delay $fill-mode $count);
}
