// Games page

//games flexbox grid
body.games, body.awards, body.games-archive {
  .page-content {
    .container {
      max-width: none;
      width: 100%;
      padding: $spacing-unit;
      margin-left: auto;
      margin-right: auto;
      box-sizing: border-box;
      @include media-query($on-palm) {
        padding: $unit/2;
      }
    }
  }
  .wrapper {
    max-width: none;
  }
}
.game-grid {
  display: flex;
  list-style: none;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: center;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  .game {
    @include media-query($on-laptop) {
      width: auto;
      height: auto;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 10px;
      box-sizing: border-box;
    }
    min-width: 300px;
    flex: 1;
    height: 215px;
    padding: 4px;
    display: block;
    border-radius: 4px;
    background-color: $cream-color;
    margin: $unit;

    .image-container {
      height: 190px;
      overflow: hidden;
      position: relative;
      background-color: $black-color;
      a:hover {
        text-decoration: none;
      }
    }
    img {
      display: block;
      min-height: 170px;
      width: 100%;
      margin-top: 30px;
      -ms-interpolation-mode: bicubic;
    }
    &:hover {
      text-decoration: none;
    }
    .game-title {
      line-height: normal;
      top: 0px;
      display: inline-block;
      position: absolute;
      background-color: $black-color;
      width: 100%;
      color: lighten($brand-primary, 10%);
      font-size: 18px;
      text-shadow: 0px 1px 6px rgba(0, 0, 72, 1);
      padding: 8px;
      box-sizing: border-box;
      &.spotlight {
        background-color: $purple;
      }
    }
    .game-developer {
      color: $black-color;
      width: 300px;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
