@font-face {
    font-family: 'londrina_solidregular';
    src: url('fonts/londrina/LondrinaSolid-Regular-webfont.eot');
    src: url('fonts/londrina/LondrinaSolid-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/londrina/LondrinaSolid-Regular-webfont.woff') format('woff'),
         url('fonts/londrina/LondrinaSolid-Regular-webfont.ttf') format('truetype'),
         url('fonts/londrina/LondrinaSolid-Regular-webfont.svg#londrina_solidregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@mixin secondary-title-font {
  font-family: Futura, "Trebuchet MS", Arial, sans-serif;
}

@mixin black-text-shadow {
  text-shadow: 0px 3px 0px rgba(40, 40, 40, 1);
}

.strong {
  font-weight: bold;
}

h1, h2, h3, h4, .site-nav {
  @include secondary-title-font;
}

.headline-em {
  @extend h1;
  font-style: italic;
}

ul.fun {
  list-style: none;
  li {
    margin-left: 12px;
  }
  li:before {
    content: "\0015E3";
    color: #6998db;
    margin-right: 5px;
  }
  li:nth-child(odd) {
    content: "\2022";
    color: #fff;
  }
  li:first-of-type {
    content: "\0015E7";
    color: #f5d500;
  }
}
//this was for the 
.archive-link {
  margin-left: 10px;
}