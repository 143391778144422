.sponsors {
  .page-content .container {
    max-width: 1200px;
  }
  .wrapper {
    text-align: center;
  }
  img {
    box-sizing: border-box;
    margin: 30px;
  }
  .one img {
    height: 300px;
  }
  .two img {
    max-height: 250px;
    max-width: 300px;
  }
  .three img {
    max-height: 150px;
    max-width: 250px;
  }
  .four img {
    max-height: 150px;
    max-width: 230px;
  }
  .five img {
    max-width: 200px;
    max-height: 100px;
  }
}
