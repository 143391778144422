body.attend {
  iframe {
    width: 60%;
    float: left;
    clear: none;
    margin: $unit;
    box-sizing: border-box;
  }
  .attend-list {
    padding: $unit;
    width: 30%;
    float: right;
  }

  @include media-query($on-laptop) {
    iframe {
      display: block;
      margin-left: $unit;
      margin-bottom: $unit;
      box-sizing: border-box;
      width: 100%;
    }
    .attend-list {
      padding: $unit;
      width: 100%;
    }
  }
}
