@include keyframes(squareDance) {
  0%, 100% {
    @include transform(translate3d(100%, 0, 0));
  }
  25% {
    @include transform(translate3d(100%, 100%, 0));
  }
  50% {
    @include transform(translate3d(0, 100%, 0));
  }
  75% {
    @include transform(translate3d(0, 0, 0));
  }
}

@mixin squareDance($duration: 3s, $delay: 0s, $fill-mode: none, $count: infinite, $timing: ease-in-out) {
  @include animation(squareDance $count $duration $delay $fill-mode $timing);
}
