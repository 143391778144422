$dark-yellow: darken($brand-primary, 20%);

@mixin button-bg($bg) {
  background: $bg;
  &:hover {
    background:darken($bg,8%);
    transition: all 0.3s ease;
    box-shadow: 0px 0px 0px 0px $dark-yellow;
  }
  &:active {
    background:darken($bg,25%);
  }
  border-radius: $brand-corner-radius;
  padding: $unit;
  margin: $unit;
  display: inline-block;
  box-shadow: 0px 5px 0px 0px $dark-yellow;
  text-align: center;
  align-self: center;
}

.button {
   @include button-bg($brand-primary);
   @include secondary-title-font;
   color: $brand-secondary;
   font-size: 30px;
   line-height: normal;
   &:hover {
     text-decoration: none;
   }
}
