body.game-jam {
  .newjam, h3 {
    text-align: center;
    font-size: 36px;
    font-family: $title-font-family;
    
  }
  .wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }
  .here {
    border-radius: $brand-corner-radius;
    flex: 1;
    margin: $unit;
  }
  iframe {
    margin: $unit;
  }
  ul {
    list-style: none;
    li {
      font-size: 20px;
      @include secondary-title-font;
    }
  }
}
