// Games page

//games flexbox grid
body.awards {
  .post-title {
    display: none;
  }
  .page-content {
    .container {
      max-width: 800px;
      width: 100%;
      padding: $spacing-unit;
      margin-left: auto;
      margin-right: auto;
      @include media-query($on-palm) {
        padding: $unit/2;
      }
    }
  }
}
