@include keyframes(fadeInZoomBig) {
  0% {
    opacity: 0;
    @include transform(scale(0));
  }
  60% {
    @include transform(scale(1.25));
  }
  80% {
    @include transform(scale(0.95));
  }
  100% {
    opacity: 1;
    @include transform(scale(1));
  }
}

@mixin fadeInZoomBig($duration: 1s, $delay: 0s, $fill-mode: forwards) {
  @include animation(fadeInZoomBig $duration $delay $fill-mode);
}
