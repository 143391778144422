
/**
 * Site footer
 */

.site-footer {
  background-color: darken($brand-secondary, 5%);
  border-top: 1px solid $grey-color-light;
  padding: $unit 0;
  color: darken($cream-color, 20%);
  font-size: 13px;
  @include secondary-title-font;
  a {
    color: $brand-primary;
    &:hover {
      color: darken($brand-primary, 10%);
      text-decoration: none;
    }
  }
}

.footer-heading {
  font-size: 18px;
  margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}
.social {
  display: flex;
  flex-direction: row;
  span {
    justify-content: space-around;
    margin-right: 20px;
  }
}

.footer-col-wrapper {
  font-size: 15px;
  color: $grey-color;
  margin-left: -$spacing-unit / 2;
  @extend %clearfix;
}
