body.games_yall {
    img {
    }
    h1 {
        text-align: center;
        margin-top: 20px;;
    }
    .wrapper {
        margin: auto;
        max-width: 600px;
        h2 {
            text-align: center;
            background-color: rgb(250,212,15);
            padding: 5px;
            border-radius: 5px;
        }
    }
    p {
    }
}